<template>
  <v-navigation-drawer
    v-model="drawerState"
    fixed
    temporary
    right
    :width="$breakpoint.is.lgAndDown && this.$vuetify.breakpoint.height < 800 ? 500 : 600"
    :class="{'menu': drawerState}"
    class="pt-2 pt-sm-4 pt-lg-10"
    color="transparent"
  >
    <section class="d-flex flex-column fill-height o-hidden">
      <div class="text-right pr-2 pr-xl-10 pr-md-12">
        <v-btn icon aria-label="Menu sluiten" @click="drawerState = !drawerState">
          <font-awesome-icon :icon="['fal', 'times']" size="2x"/>
        </v-btn>
      </div>
      <socials :col="true" :class="{'menu__socials': drawerState}" class="pr-2 pr-xl-10 pr-md-12 py-2"/>
      <navigation-items :col="true" :class="{'menu__nav grow': drawerState}" class="pr-2 pr-xl-10 pr-md-12"/>
      <contact-information :col="true" alignment="right" :class="{'menu__contact': drawerState}" class="pr-2 pr-xl-10 pr-md-12 pb-3 pb-sm-12"/>
    </section>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';
import Socials from '~/components/menu/Socials';
import NavigationItems from '~/components/menu/NavigationItems';
import ContactInformation from '~/components/menu/ContactInformation';

export default {
  components: {
    Socials,
    NavigationItems,
    ContactInformation,
  },
  computed: {
    ...mapState({
      drawerState: state => state.drawer,
    }),
    drawerState: {
      get() {
        return this.$store.state.drawer;
      },
      set(state) {
        if (state !== this.$store.state.drawer) {
          this.$store.commit('SET_DRAWER', !this.drawerState);
        }
      },
    },
  },
};
</script>

<style scoped lang="sass">
.drawer--button
  position: absolute
  z-index: 6
  right: 5%
  top: 5%

.menu
  box-shadow: none !important
  &::before
    content: ''
    animation: menu-pop-in .5s ease-in-out forwards
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: url("~assets/img/icon-menuleaf.svg") no-repeat
    background-size: 100%
    z-index: 0
    @media (max-aspect-ratio: 1/2)
      animation: menu-pop-in-small .5s ease-in-out forwards

  &__socials
    opacity: 0
    animation: social-fade-in .5s ease .7s forwards

  &__nav
    animation: nav-pop-in 2s ease forwards

  &__contact
    transform: translateX(250px)
    animation: text-slide-in-right .2s ease .5s forwards
    @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
      margin-top: 40px

@keyframes menu-pop-in
  0%
    top: 40px
    right: 56px
    bottom: 0
    left: calc(100% - 98px)
    background-size: 100%

  100%
    top: -50%
    right: -30%
    left: 20%
    bottom: 0
    background-size: 200%

@keyframes menu-pop-in-small
  0%
    top: 0
    right: 0
    bottom: 0
    left: calc(100% - 98px)
    background: var(--v-primary-lighten1)

  100%
    top: 0
    right: 0
    left: 0
    bottom: 0
    background: var(--v-primary-lighten1)

@keyframes social-fade-in
  0%
    opacity: 0

  100%
    opacity: 1

@keyframes nav-pop-in
  0%
    opacity: 0

  100%
    opacity: 1

@keyframes text-slide-in-right
  0%
    transform: translateX(200px)

  100%
    transform: translateX(-16px)
</style>
