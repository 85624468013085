<template>
  <v-app>
    <alert/>
    <app-bar/>
    <navigation-drawer/>
    <pop-up/>
    <v-content class="pt-0">
      <parallax-header :image-url="page.header_image"/>
      <nuxt/>
      <contact v-if="!isContactPage && $route.name !== 'over-ons'"/>
      <page-footer/>
    </v-content>
  </v-app>
</template>

<script>
import Alert from '~/layouts/partials/Alert';
import AppBar from '~/layouts/partials/AppBar';
import NavigationDrawer from '~/layouts/partials/NavigationDrawer';
import ParallaxHeader from '~/components/ParallaxHeader';
import PageFooter from '~/layouts/partials/PageFooter';
import Contact from '~/components/Contact';
import PopUp from '~/layouts/partials/PopUp';
import page from '~/plugins/mixins/page';

export default {
  components: {
    Alert,
    Contact,
    PageFooter,
    ParallaxHeader,
    AppBar,
    NavigationDrawer,
    PopUp,
  },
  mixins: [page],
  props: {
    image: {
      type: String,
      default: null,
    },
  },
  computed: {
    isContactPage() {
      return this.$route.name === 'contact';
    },
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://aardappelboer.nl' + this.$route.path,
        },
      ],
    };
  },
};
</script>
