<template>
  <v-img
    v-show="$route.name !== 'contact' && $breakpoint.is.mdAndUp && visible"
    :src="require('~/assets/img/icon-contactleaf.svg')"
    :width="$breakpoint.is.xl ? '12vw' : '17vw'"
    class="pop__up"
    :class="visible ? 'pop__up-img-show' : 'pop__up-img-hide'"
    contain
  >
    <v-container fill-height>
      <v-row
        justify="center"
        class="caption font-weight-bold"
      >
        <v-col offset-md="2" cols="8" align-self="end" :class="{'pop__up-text-show': visible}" class="mt-12">
          Geïnteresseerd in onze aardappels?
        </v-col>
        <v-col offset-md="2" cols="7" align-self="end" class="mb-4">
          <v-btn rounded small to="/contact" nuxt :class="visible ? 'pop__up-btn-show' : 'pop__up-btn-hide'">
            Contact
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  data: () => ({
    visible: false,
  }),
  watch: {
    '$route.path': function () {
      this.onScroll();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.scrollY > (document.body.scrollHeight / 4)) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.pop__up
  position: fixed
  left: 0
  bottom: -20%
  z-index: 9999

  &-img
    &-show
      animation: leaf-pop-in .5s ease forwards

    &-hide
      left: -12px
      animation: leaf-pop-out .5s ease .2s forwards

  &-text
    &-show
      animation: show-text .5s ease-in forwards

  &-btn
    &-show
      left: -20vw
      animation: btn-slide-in .5s ease .5s forwards

    &-hide
      animation: btn-slide-out .5s ease forwards

@keyframes leaf-pop-in
  0%
    top: 400px
    left: -20vw
    transform: rotate(180deg) scale(0.3)

  100%
    top: 0
    left: -12px

@keyframes leaf-pop-out
  0%
    top: 0
    left: -12px

  100%
    top: 400px
    left: -20vw
    transform: rotate(180deg) scale(0)

@keyframes show-text
  0%
    opacity: 0

  90%
    opacity: 0

  100%
    opacity: 1

@keyframes hide-text
  0%
    opacity: 1

  //90%
    opacity: 1

  100%
    opacity: 0

@keyframes btn-slide-in
  0%
    left: -20vw

  100%
    left: 0

@keyframes btn-slide-out
  0%
    left: 0

  100%
    left: -20vw

</style>
