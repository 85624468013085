<template>
  <section class="bg mt-12 mt-sm-0">
    <v-container v-if="this.$route.name !== 'contact'">
      <v-row align="center" class="contact__block">
        <v-col
          offset-lg="1"
          offset-xl="2"
          cols="12"
          sm="6"
          md="6"
          lg="5"
          xl="4"
          :class="isOnScreen ? 'contact__block-text-show' : 'contact__block-text-hide'"
        >
          <h3>
            Bent u geïnteresseerd<br>
            In onze aardappels?
          </h3>
          <div class="pb-4 pb-sm-6">
            Wij leveren onze aardappels zowel aan zakelijke klanten (inclusief bezorging) als aan particulieren. Voor
            informatie over mogelijkheden en prijzen kunt u vrijblijvend contact met ons opnemen. Particulieren kunnen
            zonder afspraak op maandag t/m zaterdag (8u-18u) bij ons op de boerderij in Nieuw-Vennep terecht om
            frietaardappelen te kopen.
          </div>
          <v-btn rounded small to="/contact" nuxt>Contact</v-btn>
        </v-col>
        <v-col v-if="$breakpoint.is.smAndUp" cols="3" sm="4" md="5" lg="4" xl="3">
          <img
            :src="require('~/assets/img/contact/arwin.png')"
            :class="isOnScreen ? 'contact__block-img-show' : 'contact__block-img-hide'"
            alt="Arwin Bos - De Aardappelboer"
          >
        </v-col>
        <v-container
          v-intersect="onIntersect"
          class="trigger"
        />
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row no-gutters>
        <v-col>
          <img
            :src="require('~/assets/img/contact/boerderij.png')"
            :class="isOnScreen ? 'contact__footer-img-show' : 'contact__footer-img-hide'"
            alt="De Aardappelboer"
            aria-hidden="true"
            class="mb-n2"
          >
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isOnScreen: false,
    };
  },
  methods: {
    onIntersect(entries) {
      this.isOnScreen = entries[0].isIntersecting;
    },
  },
};
</script>

<style lang="sass" scoped>
.trigger
  position: absolute
  bottom: 0
  height: 40%
  padding: 0
  pointer-events: none

.bg
  background: var(--v-secondary-lighten2)
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    background: transparent

.contact__footer-img-show
  opacity: 0
  width: 100%
  animation: img-slide-up 1s ease 0s forwards

.contact__footer-img-hide
  position: relative
  z-index: 5
  opacity: 1
  width: 100%
  animation: img-slide-down 1s ease .7s forwards

.contact__block
  transform: translateY(10%)
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    transform: translateY(18%)
  @media screen and (min-width: map-get($grid-breakpoints, 'md'))
    transform: translateY(25%)

  &-text-show
    opacity: 0
    animation: contact-text-animation-in .5s ease .5s forwards

  &-text-hide
    animation: contact-text-animation-out .5s ease forwards

  &-img-show
    opacity: 0
    animation: contact-img-animation-in 1s ease .3s forwards

  &-img-hide
    opacity: 1
    transform: translateY(16%)
    animation: contact-img-animation-out 1.5s ease .5s forwards

@keyframes img-slide-up
  0%
    opacity: 1
    transform: translateY(80%)

  100%
    opacity: 1
    transform: translateY(0%)

@keyframes img-slide-down
  0%
    opacity: 1
    transform: translateY(0%)

  100%
    opacity: 1
    transform: translateY(80%)

@keyframes contact-text-animation-in
  0%
    transform: translateY(10%)
    opacity: 0

  100%
    transform: translateY(0%)
    opacity: 1

@keyframes contact-text-animation-out
  0%
    transform: translateY(0%)
    opacity: 1

  100%
    transform: translateY(10%)
    opacity: 0

@keyframes contact-img-animation-in
  0%
    transform: translateY(90%)
    opacity: 0

  100%
    transform: translateY(16%)
    opacity: 1

@keyframes contact-img-animation-out
  0%
    opacity: 1
    transform: translateY(16%)

  100%
    opacity: 1
    transform: translateY(150%)

</style>
