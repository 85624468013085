import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e70782f = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2334e2ab = () => interopDefault(import('../pages/frietaardappel.vue' /* webpackChunkName: "pages/frietaardappel" */))
const _d87cd4ce = () => interopDefault(import('../pages/horeca.vue' /* webpackChunkName: "pages/horeca" */))
const _607133b3 = () => interopDefault(import('../pages/media.vue' /* webpackChunkName: "pages/media" */))
const _38ad77da = () => interopDefault(import('../pages/onze-werkwijze.vue' /* webpackChunkName: "pages/onze-werkwijze" */))
const _7991907c = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _3931b8a1 = () => interopDefault(import('../pages/tafel-aardappelen.vue' /* webpackChunkName: "pages/tafel-aardappelen" */))
const _9e8a74be = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _17c7370e = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _5e70782f,
    name: "contact"
  }, {
    path: "/frietaardappel",
    component: _2334e2ab,
    name: "frietaardappel"
  }, {
    path: "/horeca",
    component: _d87cd4ce,
    name: "horeca"
  }, {
    path: "/media",
    component: _607133b3,
    name: "media"
  }, {
    path: "/onze-werkwijze",
    component: _38ad77da,
    name: "onze-werkwijze"
  }, {
    path: "/over-ons",
    component: _7991907c,
    name: "over-ons"
  }, {
    path: "/tafel-aardappelen",
    component: _3931b8a1,
    name: "tafel-aardappelen"
  }, {
    path: "/",
    component: _9e8a74be,
    name: "index"
  }, {
    path: "/*",
    component: _17c7370e,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
