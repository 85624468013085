<template>
  <v-app>
    <v-img :src="require('~/assets/img/bg-error.png')" alt="Error" max-height="100vh" :cover="$breakpoint.is.xsOnly" :contain="$breakpoint.is.smAndUp">
      <v-container class="full-height">
        <v-row>
          <v-col cols="8" sm="6" md="3">
            <v-img :src="require('~/assets/img/logo.png')" contain/>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-v18">
          <v-col cols="10" sm="6" md="4">
            <template v-if="error.statusCode === 410 || error.statusCode === 404">
              <h1>
                Error<br>
                Pagina bestaat niet
              </h1>
              <p>De pagina die u probeert te bezoeken is helaas niet beschikbaar.</p>
              <v-btn
                rounded
                depressed
                to="/"
                nuxt
                color="primary darken-1"
                class="mt-8"
              >
                Naar de homepage
              </v-btn>
            </template>
            <h1 v-else>
              Er is een fout opgetreden
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-app>
</template>

<script>
import page from '~/plugins/mixins/page';

export default {
  layout: 'layout-error',
  mixins: [page],
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
  },
};
</script>
