<template>
  <v-container fluid class="footer primary py-8">
    <v-container class="py-0">
      <v-row align="center" justify="center" class="flex-column flex-sm-row">
        <v-col v-show="!$breakpoint.is.smOnly" cols="2" sm="1" class="ml-3 ml-sm-0">
          <img
            :src="require('~/assets/img/contact/branding.png')"
            :class="[{'scale-1-5': $breakpoint.is.lgAndDown}, {'scale-2': $breakpoint.is.smAndDown}]"
            alt="De Aardappelboer"
          >
        </v-col>
        <v-col
          offset-md="1"
          cols="6"
          sm="7"
          lg="5"
          xl="4"
          class="pa-0 pa-sm-3 pr-sm-0 order-2 order-sm-1"
        >
          <navigation-items/>
        </v-col>
        <v-col align-self="start" cols="12" sm="4" md="3" xl="2" class="order-1">
          <socials/>
        </v-col>
      </v-row>
    </v-container>
    <footer-items class="py-0"/>
  </v-container>
</template>

<script>
import NavigationItems from '~/components/menu/NavigationItems';
import FooterItems from '~/components/menu/FooterItems';
import Socials from '~/components/menu/Socials';

export default {
  components: {
    NavigationItems,
    FooterItems,
    Socials,
  },
};
</script>

<style lang="sass" scoped>
.footer
  position: relative
  z-index: 6

.scale-1-5
  transform: scale(1.5)

.scale-2
  transform: scale(2)
</style>
