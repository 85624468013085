<template>
  <v-container
    :class="col ? 'text-right pt-xl-10 pb-xl-7 pl-5 pr-0' : 'text-center text-sm-left pt-4 pt-xl-7'"
  >
    <div v-if="!col && $breakpoint.is.smAndUp" class="menu__items">
      <v-btn
        v-for="item in navigationItems.slice(6)"
        :key="item.text"
        :to="item.url"
        :ripple="false"
        nuxt
        text
        width="100%"
        class="btn-small pl-3 justify-start"
      >
        {{ item.text }}
      </v-btn>
    </div>
    <div v-for="social in computedSocials" :key="social.title" class="d-inline">
      <v-btn
        v-if="social.link"
        icon
        color="white"
        class="primary lighten-2 ml-3"
        :aria-label="social.title"
        :href="social.link"
        target="_blank"
        rel="noreferrer"
        :x-small="$breakpoint.is.smAndUp && !col"
        :class="{'menu__size': col || $breakpoint.is.xsOnly && !col}"
        dark
      >
        <font-awesome-icon :icon="social.icon"/>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    col: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      default: 'right',
    },
  },
  computed: {
    ...mapState({
      settingsState: state => state.settings,
      navigationItems: state => state.navigationItems,
    }),
    computedSocials() {
      return [
        {
          title: 'facebook',
          link: this.settingsState.facebook,
          icon: ['fab', 'facebook-f'],
        },
        {
          title: 'twitter',
          link: this.settingsState.twitter,
          icon: ['fab', 'twitter'],
        },
        {
          title: 'linkedin',
          link: this.settingsState.linkedin,
          icon: ['fab', 'linkedin'],
        },
        {
          title: 'youtube',
          link: this.settingsState.youtube,
          icon: ['fab', 'youtube'],
        },
        {
          title: 'instagram',
          link: this.settingsState.instagram,
          icon: ['fab', 'instagram'],
        },
      ];
    },
  },
};
</script>

<style lang="sass" scoped>
  .menu__size
    font-size: 1.33em
</style>
