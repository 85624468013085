import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

import {
  faInstagram,
  faLinkedinIn,
  faTelegramPlane,
  faTwitter,
  faFacebookF,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faInstagram,
  faLinkedinIn,
  faTelegramPlane,
  faTimes,
  faTwitter,
  faFacebookF,
  faYoutube,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
