import nl from 'vuetify/es5/locale/nl';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        anchor: '#482917',

        accent: {
          base: '#482917',
          darken1: '#2C2C2C',
        },
        primary: {
          base: '#3D4D29',
          lighten1: '#6C7E51',
          lighten2: '#88A55D',
          lighten3: '#ABD175',
          darken1: '#5C756C',
          darken2: '#455B53',
          darken3: '#2F3E39',
        },
        secondary: {
          base: '#979797',
          lighten1: '#D8D8D8',
          lighten2: '#F4F4F4',
        },
      },
    },
  },
  icons: {
    iconfont: 'faSvg',
    values: {
      calendar: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-alt'],
        },
      },
      next: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-right'],
        },
      },
      prev: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'chevron-left'],
        },
      },
    },
  },
  lang: {
    locales: {nl},
    current: 'nl',
  },
};
