export const state = () => ({
  release: process.env.RELEASE,
  settings: {},
  page: { meta: {} },
  navigationItems: [],
  footerItems: [],
  drawer: false,
});

export const actions = {
  async nuxtServerInit({commit}) {
    const [settings, navigationItems, footerItems] = await Promise.all([
      this.$axios.get(`/settings`),
      this.$axios.get(`/menu`),
      this.$axios.get(`/footer`),
    ]);
    commit('SET_SETTINGS', settings.data);
    commit('SET_NAVIGATION', navigationItems.data);
    commit('SET_FOOTER', footerItems.data);
  },

  async getPage({commit}, slug = '') {
    let page = {data: {data: {title: 'Error', meta: {title: 'Error'}}}};

    try {
      page = await this.$axios.get(`/page?segments=${slug}`);
    } catch (e) {
      const responseStatus = e.response.status;
      if (responseStatus === 404 || responseStatus === 410) {
        error({statusCode: 404, message: e.message});
      }

      console.error(e);
    }

    commit('SET_PAGE', page.data.data);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_NAVIGATION(state, payload) {
    state.navigationItems = payload;
  },
  SET_FOOTER(state, payload) {
    state.footerItems = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
};
