<template>
  <v-row justify="center" no-gutters>
    <v-col
      :cols="col || $breakpoint.is.xs ? 12 : 4"
      :class="[
        `text-${alignment}`,
        col ? 'subtitle-1' : 'bg accent--text title mt-6 mt-xl-6',
        {'caption font-weight-bold': $breakpoint.is.smAndDown}
      ]"
      class="menu__items"
    >
      <div v-if="settingsState.name">{{ settingsState.name }}</div>
      <div v-if="settingsState.address">{{ settingsState.address }}</div>
      <div v-if="settingsState.postalcode || settings.city">{{ settingsState.postalcode }} {{ settingsState.city }}</div>
      <v-btn
        v-if="settingsState.phone"
        :href="`tel: ${settingsState.phone}`"
        text
        target="_blank"
        :class="[
          col ? 'subtitle-1' : 'bg accent--text title',
          {'caption font-weight-bold': $breakpoint.is.smAndDown}
        ]"
        class="btn-small pt-3 px-0"
      >
        T: {{ settingsState.phone }}
      </v-btn>
      <br>
      <v-btn
        v-if="settingsState.email"
        :href="`mailto: ${settingsState.email}`"
        text
        target="_blank"
        :class="[
          col ? 'subtitle-1' : 'bg accent--text title',
          {'caption font-weight-bold': $breakpoint.is.smAndDown}
        ]"
        class="btn-small pt-sm-6 px-0"
      >
        M: <span class="text-lowercase">{{ settingsState.email }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    col: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    ...mapState({
      settingsState: state => state.settings,
    }),
  },
};
</script>

<style lang="sass" scoped>
.bg
  position: relative
  overflow: visible

  &:before
    content: ''
    position: absolute
    top: -20px
    left: 0
    bottom: -45px
    right: 0
    background: url('~assets/img/bg.png') no-repeat center
    background-size: contain
    z-index: -1
</style>
