// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/bg1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/img/roots.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".image[data-v-13dacf92]{position:relative;will-change:background-position}.image[data-v-13dacf92]:before{content:\"\";position:absolute;right:0;bottom:0;left:0;top:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") bottom no-repeat;background-size:contain}.roots[data-v-13dacf92]{position:relative}.roots[data-v-13dacf92]:after{content:\"\";position:absolute;right:0;bottom:0;left:0;top:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") bottom no-repeat;background-size:contain}[data-v-13dacf92] h2{font-size:34px;line-height:30px}@media screen and (min-width:960px){[data-v-13dacf92] h2{font-size:3.75rem!important;line-height:50px}}", ""]);
// Exports
module.exports = exports;
