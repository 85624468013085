export const state = () => ({
  snackbar: {
    active: false,
    message: '',
    props: {},
  },
});

const successMessage = 'Succesvol opgeslagen';

export const mutations = {
  show(state, mixed) {
    const message = successMessage;
    const props = {color: 'success'};

    if (typeof mixed === 'object') {
      state.snackbar.active = mixed.active !== false;
      state.snackbar.message = mixed.message || message;
      state.snackbar.props = mixed.props || props;
    } else if (typeof mixed === 'boolean') {
      state.snackbar.active = mixed;
      state.snackbar.message = message;
      state.snackbar.props = props;
    } else if (typeof mixed === 'string') {
      state.snackbar.active = true;
      state.snackbar.message = mixed || message;
      state.snackbar.props = props;
    } else {
      state.snackbar.active = true;
      state.snackbar.message = message;
      state.snackbar.props = props;
    }
  },
  success(state, message) {
    state.snackbar.active = true;
    state.snackbar.message = message;
    state.snackbar.props.color = 'success';
  },
  error(state, message) {
    state.snackbar.active = true;
    state.snackbar.message = message;
    state.snackbar.props.color = 'error';
  },
  saved(state) {
    state.snackbar.active = true;
    state.snackbar.message = successMessage;
    state.snackbar.props.color = 'success';
  },
};
