<template>
  <div ref="container">
    <div v-html="parsedHtmlString"></div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      parsedHtmlString: this.content,
    }
  },
  mounted() {
    this.modifyHtml('mozilla');
  },
  methods: {
    async modifyHtml(domain) {
      let html = this.content;
      let el = document.createElement('div');
      el.innerHTML = html;

      await this.parseLinks(el, domain);

      this.$refs.container.appendChild(el.innerHTML);
    },

    parseLinks(el, domain) {
      const links = el.getElementsByTagName('a');
      for (let i = 0; i < links.length; i++) {

        const link = links[i];
        console.log(links)

        if (link.href && link.href.includes(domain)) {
          const text = link.text;
          const href = link.href;

          const newEl = document.createElement('router-link');
          newEl.innerText = text;
          newEl.setAttribute('to', '/contact');

          links[i].replaceWith(newEl);
        }
      }

      return el;
    }
  }
}
</script>
