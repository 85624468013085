<template>
  <v-app-bar
    app
    flat
    color="transparent"
  >
    <nuxt-link :to="{name: 'index'}">
      <img
        :src="require('~/assets/img/logo.png')"
        alt="De Aardappelboer"
        :class="[
          {'logo--mobile': $breakpoint.is.xsOnly},
          {'logo--tab': $breakpoint.is.smOnly},
          $route.name !== 'index' ? 'logo' : 'logo--sub',
        ]"
        :style="{transform: `scale(${scale})`}"
      >
    </nuxt-link>
    <v-spacer/>
    <v-hover v-slot:default="{ hover }">
      <v-btn
        aria-label="Menu"
        :ripple="false"
        class="menu__icon pr-0 pr-md-10"
        text
        right
        @click="toggleDrawer"
      >
        <span v-show="$breakpoint.is.smAndUp" class="pr-3">Menu</span>
        <img
          :src="hover ? require('~/assets/img/icon-menu-hover.svg') : require('~/assets/img/icon-menu.svg')"
          alt="De Aardappelboer"
        >
      </v-btn>
    </v-hover>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data: () => ({
    scale: 1,
  }),
  computed: {
    ...mapState({
      drawerState: state => state.drawer,
    }),
  },
  watch: {
    '$route.path': function () {
      this.onScroll();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    toggleDrawer() {
      this.$store.commit('SET_DRAWER', !this.drawerState);
    },
    onScroll() {
      if (this.$route.name === 'index') {
        const scale = 1 - (window.scrollY * 0.3) / 100;
        if (scale > 0.55) {
          this.scale = scale;
        } else if (scale > 1) {
          this.scale = 1;
        } else {
          this.scale = 0.55;
        }
      } else {
        this.scale = 1;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.logo
  position: relative
  left: 5vw
  top: 8vh
  width: 300px
  transform-origin: top left

  &--sub
    position: relative
    left: 6vw
    top: 12vh
    width: 550px
    transform-origin: top left

  &--mobile
    position: relative
    left: 0
    top: 2vh
    width: 144px
    transform: none !important

  &--tab
    position: relative
    top: 6vh
    left: 4vw
    width: 244px
    transform: none !important

.menu__icon
  position: relative
  top: 0
  right: -6vw
  transform: scale(.7)
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    top: 6vh
    right: 2vw
    transform: none

.mobile-icon
  position: relative
  top: 0
  right: -6vw
  transform: scale(.7)

.v-btn--active::before, .v-btn:hover::before
  opacity: 0 !important
</style>
