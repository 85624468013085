<template>
  <v-container>
    <v-row class="pl-5-5 menu__items text-center text-sm-left" no-gutters>
      <v-col
        v-for="(footerItem, index) in footerItems"
        :key="footerItem.url"
        :offset-sm="index === 0 ? 2 : 0"
        :offset-lg="index === 0 ? 3 : 0"
        :offset-xl="index === 0 ? 4 : 0"
        cols="12"
        sm="2"
        xl="1"
        class="mt-sm-n6 pl-md-8 white--text"
      >
        <v-btn
          :class="index === 0 ? 'pr-sm-12' : '' "
          :ripple="false"
          nuxt
          :to="footerItem.url"
          text
          class="body-1 font-weight-regular text-capitalize d-sm-inline justify-start btn-small"
        >
          {{ footerItem.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      footerItems: state => state.footerItems,
    }),
  },
};
</script>
