<template>
  <v-img
    :src="imageUrl || ''"
    eager
    cover
    :height="height"
    :class="{'roots': $route.name === 'index'}"
    class="image"
    :position="`50% ${offset}px`"
  >
    <v-row v-if="$route.name === 'contact'" align="center" class="fill-height">
      <v-col>
        <!--eslint-disable-next-line-->
        <div v-html="page.tekst"/>
        <contact-information alignment="center" class="accent--text"/>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
import page from '~/plugins/mixins/page';
import ContactInformation from '~/components/menu/ContactInformation';

export default {
  components: {
    ContactInformation,
  },
  mixins: [page],
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    offset: 0,
  }),
  computed: {
    height() {
      if (!this.$vuetify.breakpoint.height) {
        return '100vh';
      } else if ((this.$breakpoint.is.smAndUp && this.$route.name === 'index') || (this.$breakpoint.is.smAndUp && this.$route.name === 'contact')) {
        return this.$vuetify.breakpoint.height / 1.1;
      } else if (this.$breakpoint.is.xsOnly && this.$route.name === 'contact') {
        return this.$vuetify.breakpoint.height / 0.9;
      } else if (this.$breakpoint.is.xsOnly && this.$route.name === 'index') {
        return this.$vuetify.breakpoint.height / 1.4;
      } else if (this.$breakpoint.is.xsOnly && (this.$route.name !== 'index' || this.$route.name !== 'contact')) {
        return this.$vuetify.breakpoint.height / 3.5;
      } else {
        return this.$vuetify.breakpoint.height / 1.8;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.offset = window.scrollY * 0.3;
    },
  },
};
</script>

<style lang="sass" scoped>
.image
  position: relative
  will-change: background-position

  &::before
    content: ''
    position: absolute
    right: 0
    bottom: 0
    left: 0
    top: 0
    background: url('~assets/img/bg1.png') center bottom no-repeat
    background-size: contain

.roots
  position: relative

  &:after
    content: ''
    position: absolute
    right: 0
    bottom: 0
    left: 0
    top: 0
    background: url('~assets/img/roots.png') center bottom no-repeat
    background-size: contain

::v-deep h2
  font-size: 34px
  line-height: 30px
  @media screen and (min-width: map-get($grid-breakpoints, 'md'))
    font-size: map-deep-get($headings, 'h2', 'size') !important
    line-height: 50px
</style>
