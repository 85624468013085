var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"menu__items",class:[
      ("text-" + _vm.alignment),
      _vm.col ? 'subtitle-1' : 'bg accent--text title mt-6 mt-xl-6',
      {'caption font-weight-bold': _vm.$breakpoint.is.smAndDown}
    ],attrs:{"cols":_vm.col || _vm.$breakpoint.is.xs ? 12 : 4}},[(_vm.settingsState.name)?_c('div',[_vm._v(_vm._s(_vm.settingsState.name))]):_vm._e(),_vm._v(" "),(_vm.settingsState.address)?_c('div',[_vm._v(_vm._s(_vm.settingsState.address))]):_vm._e(),_vm._v(" "),(_vm.settingsState.postalcode || _vm.settings.city)?_c('div',[_vm._v(_vm._s(_vm.settingsState.postalcode)+" "+_vm._s(_vm.settingsState.city))]):_vm._e(),_vm._v(" "),(_vm.settingsState.phone)?_c('v-btn',{staticClass:"btn-small pt-3 px-0",class:[
        _vm.col ? 'subtitle-1' : 'bg accent--text title',
        {'caption font-weight-bold': _vm.$breakpoint.is.smAndDown}
      ],attrs:{"href":("tel: " + (_vm.settingsState.phone)),"text":"","target":"_blank"}},[_vm._v("\n      T: "+_vm._s(_vm.settingsState.phone)+"\n    ")]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.settingsState.email)?_c('v-btn',{staticClass:"btn-small pt-sm-6 px-0",class:[
        _vm.col ? 'subtitle-1' : 'bg accent--text title',
        {'caption font-weight-bold': _vm.$breakpoint.is.smAndDown}
      ],attrs:{"href":("mailto: " + (_vm.settingsState.email)),"text":"","target":"_blank"}},[_vm._v("\n      M: "),_c('span',{staticClass:"text-lowercase"},[_vm._v(_vm._s(_vm.settingsState.email))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }