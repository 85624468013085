<template>
  <v-container class="menu__items">
    <v-row v-if="col" align="center" class="flex-column">
      <v-col v-for="item in navigationItems" :key="col ? `${item.text}-side` : item.text" class="pa-0 pr-3 text-right">
        <v-btn
          text
          :to="item.url"
          nuxt
          :ripple="false"
          :class="$vuetify.breakpoint.height < 825 ? 'subtitle-1' : 'title'"
          class="px-0 justify-end"
        >
          {{ item.text }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="space-between" no-gutters>
      <v-col cols="12" sm="6">
        <v-btn
          v-for="item in navigationItems.slice(0, 3)"
          :key="item.text"
          :to="item.url"
          :ripple="false"
          nuxt
          text
          block
          :class="{'subtitle-1': $breakpoint.is.xs}"
          class="justify-center justify-sm-start btn-small"
        >
          {{ item.text }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn
          v-for="item in navigationItems.slice(3)"
          :key="`${item.text}-xs`"
          :to="item.url"
          :ripple="false"
          nuxt
          text
          :class="{'subtitle-1': $breakpoint.is.xs}"
          class="justify-center d-flex d-sm-none btn-small"
        >
          {{ item.text }}
        </v-btn>
        <v-btn
          v-for="item in navigationItems.slice(3, 6)"
          :key="item.text"
          :to="item.url"
          :ripple="false"
          nuxt
          text
          class="btn-small justify-start d-none d-sm-flex"
        >
          {{ item.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    col: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      navigationItems: state => state.navigationItems,
    }),
  },
};
</script>
